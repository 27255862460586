import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const allRoute = [

    {
        path: '/CustomerList',
        name: '客户列表',
        component: () => import('@/views/customerList/CustomerList')
    },
    {
        path: '/CustomerListAdd',
        name: '客户添加',
        component: () => import('@/views/customerList/CustomerListAdd')
    },
    {
        path: '/BulkCrmsCusAddressList/:name',
        name: '客户详情地址/联系人列表',
        component: () => import('@/views/bulkCrmsCusAddress/BulkCrmsCusAddressList')
    },
    {
        path: '/BulkCrmsCusAddressListLs/:name',
        name: '客户详情地址临时/联系人列表',
        component: () => import('@/views/bulkCrmsCusAddress/BulkCrmsCusAddressListLs')
    },
    {
        path: '/BulkCrmsCusAddressAdd/:name',
        name: '客户地址添加',
        component: () => import('@/views/bulkCrmsCusAddress/BulkCrmsCusAddressAdd'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    },
    {
        path: '/BulkCrmsCusAddressAddLs/:name',
        name: '客户地址添加临时',
        component: () => import('@/views/bulkCrmsCusAddress/BulkCrmsCusAddressAddLs'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    },
    {
        path: '/BulkCrmsCusAddressEdit/:name',
        name: '客户地址修改',
        component: () => import('@/views/bulkCrmsCusAddress/BulkCrmsCusAddressEdit')
    },
    {
        path: '/BulkCrmsCusAddressEditLs/:name',
        name: '客户地址修改临时',
        component: () => import('@/views/bulkCrmsCusAddress/BulkCrmsCusAddressEditLs')
    },
    {
        path: '/BulkCrmsCusAddressDetailList/:name',
        name: '客户详情地址/联系人列表',
        component: () => import('@/views/bulkCrmsCusAddressDetail/BulkCrmsCusAddressDetailList')
    },
    {
        path: '/BulkCrmsCusAddressDetailListLs/:name',
        name: '客户详情地址临时/联系人列表',
        component: () => import('@/views/bulkCrmsCusAddressDetail/BulkCrmsCusAddressDetailListLs')
    },
    {
        path: '/BulkCrmsCusAddressDetailAdd/:name',
        name: '客户详情地址/联系人添加',
        component: () => import('@/views/bulkCrmsCusAddressDetail/BulkCrmsCusAddressDetailAdd'),
        meta: {
            keepAlive: false,
            isBack: false
        }
    },
    {
        path: '/BulkCrmsCusAddressDetailAddLs/:name',
        name: '客户详情地址临时/联系人添加',
        component: () => import('@/views/bulkCrmsCusAddressDetail/BulkCrmsCusAddressDetailAddLs'),
        meta: {
            keepAlive: false,
            isBack: false
        }
    },
    {
        path: '/BulkCrmsCusAddressDetailEdit/:name',
        name: '客户详情地址/联系人修改',
        component: () => import('@/views/bulkCrmsCusAddressDetail/BulkCrmsCusAddressDetailEdit')
    },
    {
        path: '/BulkCrmsCusAddressDetailEditLs/:name',
        name: '客户详情地址临时/联系人修改',
        component: () => import('@/views/bulkCrmsCusAddressDetail/BulkCrmsCusAddressDetailEditLs')
    },
    {
        path: '/CustomerListAdd/:name',
        name: '客户添加页面',
        component: () => import('@/views/customerList/CustomerListAdd'),
        meta: {
            keepAlive: false,
            isBack: false
        }
    },
    {
        path: '/CustomerListEdit/:name',
        name: '客户编辑页面(货方)',
        component: () => import('@/views/customerList/CustomerListEdit')
    },
    {
        path: '/CustomerListEditCF/:name',
        name: '客户编辑页面(船方)',
        component: () => import('@/views/customerList/CustomerListEditCF')
    },
    {
        path: '/CustomerListEditGF/:name',
        name: '客户编辑页面(港方)',
        component: () => import('@/views/customerList/CustomerListEditGF')
    },
    {
        path: '/MailList',
        name: '货方客户通讯录',
        component: () => import('@/views/MailList')
    },
    {
        path: '/MailListDetail/:id',
        name: '货方客户通讯录添加/查看',
        component: () => import('@/views/MailListDetail')
    },
    {
        path: '/BulkCrmsShipInfoList/:id',
        name: '船舶信息',
        component: () => import('@/views/bulkCrmsShipInfo/BulkCrmsShipInfoList')
    },
    {
        path: '/BulkCrmsShipInfoDetailList/:id',
        name: '船舶信息详情编辑',
        component: () => import('@/views/bulkCrmsShipInfo/BulkCrmsShipInfoDetailList')
    },
    {
        path: '/BulkCrmsShipInfoDetailAdd/:id',
        name: '船舶信息详情添加',
        component: () => import('@/views/bulkCrmsShipInfo/BulkCrmsShipInfoDetailAdd')
    },
    {
        path: '/BulkCrmsPortInfoList/:id',
        name: '港口信息详情编辑',
        component: () => import('@/views/bulkCrmsPortInfo/BulkCrmsPortInfoList')
    },
    {
        path: '/BulkCrmsPortInfoDetailList/:id',
        name: '港口信息详情编辑',
        component: () => import('@/views/bulkCrmsPortInfo/BulkCrmsPortInfoDetailList')
    },
    {
        path: '/BulkCrmsPortInfoDetailAdd/:id',
        name: '港口信息新增',
        component: () => import('@/views/bulkCrmsPortInfo/BulkCrmsPortInfoDetailAdd')
    },
    {
        path: '/BulkCrmsTravelPlanList',
        name: '走访计划',
        component: () => import('@/views/bulkCrmsTravelPlan/BulkCrmsTravelPlanList')
    },
    {
        path: '/BulkCrmsTravelPlanDetail/:id',
        name: '走访计划详情',
        component: () => import('@/views/bulkCrmsTravelPlan/BulkCrmsTravelPlanDetail')
    },
    {
        path: '/BulkCrmsTravelPlanAdd/:name',
        name: '走访计划添加',
        component: () => import('@/views/bulkCrmsTravelPlan/BulkCrmsTravelPlanAdd')
    },
    {
        path: '/BulkCrmsTravelPlanRecord/:id',
        name: '走访计划方案详情',
        component: () => import('@/views/bulkCrmsTravelPlan/BulkCrmsTravelPlanRecord')
    },
    {
        path: '/BulkCrmsTravelPlanRecordAdd/:name/:id/:start/:end/:type',
        name: '走访计划方案添加',
        component: () => import('@/views/bulkCrmsTravelPlan/BulkCrmsTravelPlanRecordAdd')
    },
    {
        path: '/BulkCrmsVisitReplayList/',
        name: '走访复盘',
        component: () => import('@/views/bulkCrmsVisitReplay/BulkCrmsVisitReplayList')
    },
    {
        path: '/BulkCrmsVisitReplayEdit/:id',
        name: '走访复盘编辑',
        component: () => import('@/views/bulkCrmsVisitReplay/BulkCrmsVisitReplayEdit')
    },
    {
        path: '/BulkCrmsVisitReplayDetail/:id',
        name: '走访复盘列表',
        component: () => import('@/views/bulkCrmsVisitReplay/BulkCrmsVisitReplayDetail')
    },
    {
        path: '/BulkCrmsVisitReplayDetailEdit/:id',
        name: '走访复盘详情编辑',
        component: () => import('@/views/bulkCrmsVisitReplay/BulkCrmsVisitReplayDetailEdit'),
        meta: {
            keepAlive: false,
            isBack: false
        }
    },
    {
        path: '/BulkCrmsPushReplayDetailEdit/:id',
        name: '领导批复详情页面',
        component: () => import('@/views/bulkCrmsVisitReplay/BulkCrmsPushReplayDetailEdit'),
    },
    {
        path: '/BulkCrmsPushReplayEdit/:id',
        name: '领导批复页面',
        component: () => import('@/views/bulkCrmsVisitReplay/BulkCrmsPushReplayEdit')
    },
    {
        path: '/FrequencyRemindSelect/:id',
        name: '频次提醒选择',
        component: () => import('@/views/frequencyRemind/FrequencyRemindSelect')
    },
    {
        path: '/FrequencyRemindPhone/:id',
        name: '回访推送',
        component: () => import('@/views/frequencyRemind/FrequencyRemindPhone')
    },
    {
        path: '/FrequencyRemindGo/:id',
        name: '频次提醒走访',
        component: () => import('@/views/frequencyRemind/FrequencyRemindGo')
    },
    {
        path: '/FrequencyRemindOther/',
        name: '频次提醒其他',
        component: () => import('@/views/frequencyRemind/FrequencyRemindOther')
    },
    {
        path: '/BulkCrmsSelCompany/',
        name: '临时拜访',
        component: () => import('@/views/bulkCrmsVisitTemporary/BulkCrmsSelCompany')
    },
    {
        path: '/BulkCrmsVisitTemporaryExistList/',
        name: '临时拜访已有客户列表',
        component: () => import('@/views/bulkCrmsVisitTemporary/bulkCrmsVisitTemporaryExist/BulkCrmsVisitTemporaryExistList')
    },
    {
        path: '/BulkCrmsVisitTemporaryExistAdd/:name',
        name: '临时拜访已有客户添加',
        component: () => import('@/views/bulkCrmsVisitTemporary/bulkCrmsVisitTemporaryExist/BulkCrmsVisitTemporaryExistAdd')
    },
    {
        path: '/BulkCrmsVisitTemporaryExistEdit/:id',
        name: '临时拜访已有客户修改',
        component: () => import('@/views/bulkCrmsVisitTemporary/bulkCrmsVisitTemporaryExist/BulkCrmsVisitTemporaryExistEdit')
    },
    {
        path: '/BulkCrmsVisitTemporaryNewList/',
        name: '临时拜访新增客户列表',
        component: () => import('@/views/bulkCrmsVisitTemporary/bulkCrmsVisitTemporaryNew/BulkCrmsVisitTemporaryNewList')
    },
    {
        path: '/BulkCrmsVisitTemporaryNewAdd/:name',
        name: '临时拜访新增客户添加',
        component: () => import('@/views/bulkCrmsVisitTemporary/bulkCrmsVisitTemporaryNew/BulkCrmsVisitTemporaryNewAdd')
    },
    {
        path: '/BulkCrmsVisitTemporaryNewEdit/',
        name: '临时拜访新增客户修改',
        component: () => import('@/views/bulkCrmsVisitTemporary/bulkCrmsVisitTemporaryNew/BulkCrmsVisitTemporaryNewEdit')
    },
    {
        path: '/EarlyWarningPlatform/',
        name: '监控中心',
        component: () => import('@/views/earlyWarningPlatform/EarlyWarningPlatform')
    },
    {
        path: '/EarlyWarningHistoryList/:name',
        name: '历史记录',
        component: () => import('@/views/earlyWarningPlatform/EarlyWarningHistoryList')
    },
    {
        path: '/CustomerArea/',
        name: '区域客户',
        component: () => import('@/views/customerArea/CustomerArea')
    },
    {
        path: '/CustomerRelationPanel/',
        name: '客户关系看板',
        component: () => import('@/views/bulkCrmsReportManage/CustomerRelationPanel')
    },
    {
        path: '/TravelFrequencyPanel/',
        name: '出差频次看板',
        component: () => import('@/views/bulkCrmsReportManage/TravelFrequencyPanel')
    },
    {
        path: '/FrequencyDetail/:name',
        name: '频次详细列表',
        component: () => import('@/views/bulkCrmsReportManage/FrequencyDetail')
    },
    {
        path: '/CustomerEjList/:name',
        name: '客户信息',
        component: () => import('@/views/CustomerEjList')
    },
    {
        path: '/CustomerTwoList',
        name: '二级客户',
        component: () => import('@/views/CustomerTwoList')
    },
    {
        path: '/CustomerTwoShowHF',
        name: '二级客户详情编辑',
        component: () => import('@/views/CustomerTwoShowHF')
    },
    {
        path: '/ProjectProgressRecord',
        name: '项目推进记录',
        meta: { keepAlive: true },
        component: () => import('@/views/ProjectProgressRecord')
    },
    {
        path: '/ProjectProgressRecord1/:name',
        name: '项目推进记录',
        component: () => import('@/views/ProjectProgressRecord1')
    },
    {
        path: '/ProjectProgressDetail',
        name: '项目推进',
        component: () => import('@/views/ProjectProgressDetail')
    },
    {
        path: '/ProjectRecordDetail',
        name: '项目推进详情',
        component: () => import('@/views/ProjectRecordDetail')
    },
    {
        path: '/LeaderOfficialPanel',
        name: '待批复看板',
        component: () => import('@/views/leaderPanel/LeaderOfficialPanel')
    },
    {
        path: '/LeaderConcernPanel',
        name: '关注看板',
        component: () => import('@/views/leaderPanel/LeaderConcernPanel')
    },
    {
        path: '/RemainProblerms',
        name: '遗留问题',
        component: () => import('@/views/remainProblerms/RemainProblerms')
    },
    {
        path: '/RemainProblermsDetail/:title/:id',
        name: '遗留问题详情',
        component: () => import('@/views/remainProblerms/RemainProblermsDetail')
    },
    {
        path: '/RemainProblermsEdit/:title/:id',
        name: '遗留问题编辑',
        component: () => import('@/views/remainProblerms/RemainProblermsEdit')
    },
    {
        path: '/RemainProblermsAdd/:title/:id',
        name: '解决方案新增',
        component: () => import('@/views/remainProblerms/RemainProblermsAdd')
    },
    {
        path: '/LineDetail/:name',
        name: '航线明细',
        component: () => import('@/views/LineDetail')
    },
    {
        path: '/SteelNo',
        name: '智能配箱',
        component: () => import('@/views/steelNo/SteelNo')
    },
    {
        path: '/WxCrowd',
        name: '微信群消息',
        component: () => import('@/views/steelNo/WxCrowd')
    },
    {
        path: '/SteelNoJzx',
        name: '天津装箱',
        component: () => import('@/views/steelNo/SteelNoJzx')
    },
    {
        path: '/BidPanel',
        name: '招标看板',
        component: () => import('@/views/bidPanel/BidPanel')
    },
    {
        path: '/Bulk1/:id',
        name: '招标信息池-电煤招标建发新详情',
        component: () => import('@/views/bidPanel/Bulk1')
    },
    {
        path: '/Bulk2/:id',
        name: '招标信息池-电煤招标华远星详情',
        component: () => import('@/views/bidPanel/Bulk2')
    },
    {
        path: '/Bulk3/:id',
        name: '招标信息池-电煤招标华能详情',
        component: () => import('@/views/bidPanel/Bulk3')
    },
    {
        path: '/Bulk4/:id',
        name: '招标信息池-电煤招标国能详情',
        component: () => import('@/views/bidPanel/Bulk4')
    },
    {
        path: '/Bulk5/:id',
        name: '招标信息池-电煤招标三宝详情',
        component: () => import('@/views/bidPanel/Bulk5')
    },
    {
        path: '/Bulk6/:id',
        name: '招标信息池-钢煤招标敬业详情',
        component: () => import('@/views/bidPanel/Bulk6')
    },
    {
        path: '/Bulk7/:id',
        name: '招标信息池-电煤招标建发详情',
        component: () => import('@/views/bidPanel/Bulk7')
    },
    {
        path: '/Bulk8/:id',
        name: '招标信息池-电煤招标物产详情',
        component: () => import('@/views/bidPanel/Bulk8')
    },
    {
        path: '/Bulk9/:id',
        name: '招标信息池-厦门国贸详情',
        component: () => import('@/views/bidPanel/Bulk9')
    },
    {
        path: '/Bulk10/:id',
        name: '招标信息池-大唐集团',
        component: () => import('@/views/bidPanel/Bulk10')
    },
    {
        path: '/Bulk11/:id',
        name: '招标信息池-中粮招标',
        component: () => import('@/views/bidPanel/Bulk11')
    },
    {
        path: '/Bulk12/:id',
        name: '煤炭招标-宁波海运',
        component: () => import('@/views/bidPanel/Bulk12')
    },
    {
        path: '/BidPanelAuthority',
        name: '招标权限',
        component: () => import('@/views/bidPanel/BidPanelAuthority')
    },
    {
        path: '/WarningPanelIndex',
        name: '预警看板平台',
        component: () => import('@/views/warningPanel/WarningPanelIndex')
    },
    {
        path: '/WarningPanel/:id',
        name: '预警看板',
        component: () => import('@/views/warningPanel/WarningPanel')
    },
    {
        path: '/WarningPanelDetail/:id',
        name: '预警看板详情',
        component: () => import('@/views/warningPanel/WarningPanelDetail')
    },
    {
        path: '/WarningCustomer',
        name: '客户风险详情',
        component: () => import('@/views/warningPanel/WarningCustomer')
    },
    {
        path: '/WarningExceed',
        name: '应收超额预警',
        component: () => import('@/views/warningPanel/WarningExceed')
    },
    {
        path: '/WarningExceedDetail',
        name: '应收超额预警详情',
        component: () => import('@/views/warningPanel/WarningExceedDetail')
    },
    {
        path: '/WarningExceedPushDetail/:id',
        name: '应收超额预警推送详情',
        component: () => import('@/views/warningPanel/WarningExceedPushDetail')
    },
    {
        path: '/CustomerOa',
        name: 'OA信息同步',
        component: () => import('@/views/customerList/CustomerOa')
    },
    {
        path: '/CustomerOaDetail/:name',
        name: 'OA信息同步详情',
        component: () => import('@/views/customerList/CustomerOaDetail')
    },
    {
        path: '/ExcelPhone',
        name: 'excel数据呈现',
        component: () => import('@/views/excelPhone/ExcelPhone')
    },
    {
        path: '/Costing',
        name: '成本核算页面',
        component: () => import('@/views/costing/Costing')
    },
    {
        path: '/CostingContrast',
        name: '成本对比页面',
        component: () => import('@/views/costing/CostingContrast')
    },
    {
        path: '/FileViewer',
        name: '操作手册',
        component: () => import('@/views/fileViewer/FileViewer')
    },
    {
        path: '/FileDetail/:name',
        name: '操作手册详情',
        component: () => import('@/views/fileViewer/FileDetail')
    },
    {
        path: '/FileDetailOne/:name',
        name: '操作手册详情',
        component: () => import('@/views/fileViewer/FileDetailOne')
    },
    {
        path: '/ClassicCase',
        name: '经典案例详情',
        component: () => import('@/views/fileViewer/ClassicCase')
    },
    {
        path: '/DigitalEmployees',
        name: '数字员工',
        component: () => import('@/views/DigitalEmployees')
    },
    {
        path: '/DigitalApp',
        name: '员工应用',
        component: () => import('@/views/DigitalApp')
    },
    {
        path: '/DigitalAsset',
        name: '数字资产',
        component: () => import('@/views/DigitalAsset')
    },
    {
        path: '/DigitalFiles',
        name: '员工档案',
        component: () => import('@/views/DigitalFiles')
    },
    {
        path: '/DigitalGrow',
        name: '员工成长',
        component: () => import('@/views/DigitalGrow')
    },
    {
        path: '/DigitalGrowDetail/:id',
        name: '员工成长详情',
        component: () => import('@/views/DigitalGrowDetail')
    },
    {
        path: '/DigitalGrowDetailEdit/:id',
        name: '员工成长经历',
        component: () => import('@/views/DigitalGrowDetailEdit')
    },
    {
        path: '/DigitalGrowDetailList/:id',
        name: '员工成长经历',
        component: () => import('@/views/DigitalGrowDetailList')
    },
    {
        path: '/Waiting',
        name: '开发中',
        component: () => import('@/views/Waiting')
    },
    {
        path: '/CustomerPayer',
        name: '客户发运量统计',
        component: () => import('@/views/customerList/CustomerPayer')
    },
    {
        path: '/CustomerPayerTo/:name',
        name: '客户发运量统计',
        component: () => import('@/views/customerList/CustomerPayerTo')
    },
    {
        path: '/DigitalDangAn',
        name: '员工档案',
        component: () => import('@/views/DigitalDangAn')
    },
    {
        path: '/DigitalRecord/:id',
        name: '员工档案详情',
        component: () => import('@/views/DigitalRecord')
    },
    {
        path: '/BulkCrmsTravelTodoList',
        name: '待处理推送',
        component: () => import('@/views/bulkCrmsTravelPlan/BulkCrmsTravelTodoList')
    },
    {
        path: '/MyCustomer',
        name: '我的客户',
        component: () => import('@/views/customerList/MyCustomer')
    },
    {
        path: '/CustomerManage/:id',
        name: '客户运维',
        component: () => import('@/views/customerList/CustomerManage')
    },
]

const routes = [
    {
        path: '/index',
        name: '首页',
        component: () => import('@/views/Index')
    },
    {
        path: '/NotFound',
        name: 'NotFound',
        component: () => import(('@/views/NotFound'))
    },
    {path: '/login', name: '授权', component: () => import('@/views/Auth')},
    {path: '/auth', name: '授权', component: () => import('@/views/Auth')},
    {path: '/', redirect: '/index'},
    {path: '*', redirect: '/NotFound'},
]
// 白名单
export const whileList = ['/auth', '/noAuthority', '/404']

const createRouter = () => new Router({
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: routes.concat(allRoute)
})

const router = createRouter()

export default router
