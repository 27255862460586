import {post} from "@/api/index";

export function getUserInfoByWechatCode(code) {
    return post('/api/getUserInfoByWechatCode', {code})
}

export function getJsApiParams(url) {
    return post('/api/getJsApiParams', {url: url})
}

export function getWXSignature(url) {
    return post('/api/getJSAPITicket', {url: url})
}