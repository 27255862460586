import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import httpService from '@/api'
import './permission'
import Vant from 'vant';
import 'vant/lib/index.css';
//引入element-ui的全部组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import { download } from '@/utils/request'
//引入element-ui的css
import 'element-ui/lib/theme-chalk/index.css'
import utils from '@/utils'
import * as echarts from 'echarts';
import VueScrollLock from 'vue-scroll-lock';
import '@/assets/icon_font/iconfont.css';

//使用elementUI
Vue.use(ElementUI)
Vue.use(VueScrollLock)

Vue.config.productionTip = false
Vue.prototype.$ajax = httpService
Vue.prototype.$utils = utils
Vue.prototype.$echarts = echarts
Vue.prototype.download = download

Vue.use(Vant);

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
