import axios from 'axios'
import store from "@/store";
import router from "@/router";

// 封装请求
export function request(url, options) {

    const token = store.getters.token;
    const userId = store.getters.userId;
    const path = router.currentRoute.path;
    const defaultOptions = {
        headers: {
            'Content-Type': "application/json;charset=UTF-8"
        },

        withCredentials: true,
        url: url,
        baseURL: '',
        timeout: 30000
    };
    token && (defaultOptions.headers.Authorization = token)
    userId && (defaultOptions.headers.userId = userId)
    path && (defaultOptions.headers.path = path)

    const newOptions = {...options, ...defaultOptions};
    return axios.request(newOptions)
        .then(res => {
            return checkStatus(res, url, options)
        })
}

// 默认纸条
let isRefreshing = true;

function checkStatus(response, url, options) {
    // 请求头中有token则设置新的token
    response.headers.authorization && store.commit('COMMIT_TOKEN', response.headers.authorization)

    if (response && response.data.status === 401) {
        // 刷新token的函数,这需要添加一个开关，防止重复请求
        if (isRefreshing) {
            refreshTokenRequest()
        }
        isRefreshing = false;
        // 将当前的请求保存在观察者数组中
        return new Promise((resolve) => {
            addSubscriber(() => {
                resolve(request(url, options))
            })
        });
    } else if (response && (!response.data instanceof Array && response.data.status !== 200)) {
        throw new Error(response.data.status + ':' + response.data.message)
    } else {
        return response;
    }
}

function refreshTokenRequest() {
    post('/api/refreshToken').then(() => {
        // 并且将所有存储到观察者数组中的请求重新执行。
        onAccessTokenFetched();
        // 纸条撕掉
        isRefreshing = true;
    });
}

// 观察者
let subscribers = [];

function onAccessTokenFetched() {
    subscribers.forEach((callback) => {
        callback();
    })
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback)
}

export function get(url, params) {
    return new Promise((resolve, reject) => {
        request(url, {
            method: 'get',
            params: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        request(url, {
            method: 'post',
            data: params
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}

export function setSessionStorage(key, value) {
    sessionStorage.setItem(key, value)
}

export function getSessionStorage(key) {
    return sessionStorage.getItem(key)
}

export function removeSessionStorage(key) {
    sessionStorage.removeItem(key)
}

export default axios
