import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import router from '@/router'
import store from "@/store";

NProgress.configure({showSpinner: false})

import {getArrEqual} from '@/utils/util'
import {whileList} from '@/router'
import {getJsApiParams} from '@/api/routeApi'
// import jWeixin from 'weixin-jsapi';

router.beforeEach(async (to, from, next) => {
    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
        window.entryUrl = location.href.split('#')[0]
    }

    document.title = to.name;
    console.log('from', from)
    NProgress.start()
    if (whileList.includes(to.path)) {
        console.log("访问", to.path, "白名单放行")
        return next()
    }
    // 如果token不存在
    if (!store.getters.token) {
        if (to.path === '/login') {
            console.log("访问", to.path, "token不存在，访问登陆")
            return next()
        } else {
            console.log("访问", to.path, "token不存在，跳转登陆")
            return next({path: '/login'})
        }
    } else {
        if (to.path === '/login') {
            console.log("访问", to.path, "token存在，跳转web首页")
            return next({path: '/index'})
        }
        //如果路由不需要角色控制，直接放行
        if (!to.meta.roles) {
            console.log("访问", to.path, "token存在，不需要角色校验")
            return next()
        } else {
            let arr = getArrEqual(to.meta.roles, store.getters.roles)
            if (arr.length > 0) {
                console.log("访问", to.path, "token存在，角色有权限访问")
                return next()
            } else {
                console.log("访问", to.path, "token存在，角色无权限访问，跳转错误页面")
                return next({path: '/noAuthority', query: {message: '没有权限访问'}})
            }
        }
    }
})
router.afterEach(() => {
    let path = location.href.split('?')[1] ? (router.currentRoute.path + '?' + location.href.split('?')[1]) : router.currentRoute.path
    getForshare(location.href.split('#')[0], path)

    NProgress.done()
})

function getForshare(url, path) {
    getJsApiParams(url).then(res => {
        const conf = res.data.data
        // eslint-disable-next-line no-undef
        wx.config({
            beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: conf.appId, // 必填，企业微信的corpID
            timestamp: conf.timestamp, // 必填，生成签名的时间戳
            nonceStr: conf.noncestr, // 必填，生成签名的随机串
            signature: conf.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
            jsApiList: ['onMenuShareAppMessage', 'onMenuShareWechat', 'checkJsApi', 'openLocation', 'getLocation'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        // eslint-disable-next-line no-undef
        wx.checkJsApi({
            jsApiList: ['shareAppMessage', 'shareWechatMessage', 'checkJsApi', 'openLocation', 'getLocation'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                console.log("校验是否可用", res)
            }
        });
        // eslint-disable-next-line no-undef
        wx.ready(function () {
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
            // eslint-disable-next-line no-undef
            wx.onMenuShareAppMessage({
                title: 'CRM', // 分享标题
                desc: 'CRM-WECHAT', // 分享描述
                link: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwde741ca7dcc80764&redirect_uri=http%3A%2F%2Fdlcrm.trawind.com/auth&response_type=code&scope=snsapi_base&agentid=1000033&state=' + path + '#wechat_redirect', // 分享链接
                imgUrl: '', // 分享封面
                success: function () {
                    // 用户确认分享后执行的回调函数
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });
            // eslint-disable-next-line no-undef
            wx.onMenuShareWechat({
                title: 'CRM', // 分享标题
                desc: 'CRM-WECHAT', // 分享描述
                link: 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwde741ca7dcc80764&redirect_uri=http%3A%2F%2Fdlcrm.trawind.com/auth&response_type=code&scope=snsapi_base&agentid=1000033&state=' + path + '#wechat_redirect', // 分享链接
                imgUrl: '', // 分享封面
                success: function () {
                    // 用户确认分享后执行的回调函数
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                }
            });
        });
    })
}
