import {getSessionStorage, setSessionStorage} from '@/api'

const state = {
    userId: getSessionStorage('userId'),
    token: getSessionStorage('token'),
    roles: getSessionStorage('roles') ? getSessionStorage('roles').split(',') : [],
    scroll: getSessionStorage('scroll'),
    name: getSessionStorage('name'),
}

const mutations = {
    COMMIT_TOKEN(state, token) {
        setSessionStorage('token', token)
        state.token = token
    },
    COMMIT_USERID(state, userId) {
        setSessionStorage('userId', userId)
        state.userId = userId
    },
    COMMIT_NAME(state, name) {
        setSessionStorage('name', name)
        state.name = name
    },
    COMMIT_SCROLL(state, scroll) {
        setSessionStorage('scroll', scroll)
        state.scroll = scroll
    },
    COMMIT_ROLES(state, roles) {
        setSessionStorage('roles', roles.join(','))
        state.roles = getSessionStorage('roles').split(',')
    },
}

const action = {}


export default {
    namespace: true,
    state,
    mutations,
    action
}
